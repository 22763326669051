<template>
  <div class="full-height-preview" :class="topicStore.topic.color">
    <BackgroundWaves color="#292827" />
    <v-container class="wrapper-container" fluid>
      <v-container class="top-bar">
        <NavigationButtonLeftComponent @back="back"></NavigationButtonLeftComponent>
        <v-spacer></v-spacer>
        <v-img class="home-img-logo" :src="LoginLogo"></v-img>
      </v-container>
    </v-container>
    <v-container class="wrapper-container" fluid>
      <v-container class="preview-container">

        <v-container class="container-preview-view" fluid :style="{ backgroundColor: topicStore.topic.BgColor }">
          <v-container class="preview-topic-card-container" fluid>
            <img class="img-cards" :src="topic.picture_base64" alt="Bild">
            <v-container class="preview-topic-card">
              <h3 class="count-questions">{{ questionCount }} Fragen</h3>
              <h1>{{ topic.title }}</h1>
              <p>
                {{ topic.description }}
              </p>
              <v-btn @click="startQuiz" class="preview-view-btn" :disabled="checkedQuestions.length === 0"
                :style="{ backgroundColor: topicStore.topic.BtnColor }">Los Geht's</v-btn>
            </v-container>
          </v-container>
        </v-container>
        <v-container class="content-of-topic" fluid>
          <v-container class="preview-show-container" fluid>
            <v-row fluid>
              <v-col cols="8">
                <h1>Inhalt des Themenbereichs</h1>
              </v-col>
              <v-col cols="4" class="preview-show-button-container">
                <v-btn class="preview-show-button" @click="showContent">{{ buttonText }}</v-btn>
              </v-col>
            </v-row>
          </v-container>
          <template v-for="(question, index) in questionsNundD" :key="index">
            <v-container class="questions-container" v-show="showContents" fluid>
              <div v-if="question.status == 'draft'" class="disruptor">Coming Soon</div>
              <v-container class="preview-show-checkbox-container" fluid>
                <h3 class="preview-show-checkbox-label" :style="{ opacity: question.status == 'draft' ? 0.5 : 1 }">Ins
                  Quiz aufnehmen</h3>
                <v-checkbox v-model="checkedQuestions" :value="index" true-icon="check_box"
                  false-icon="check_box_outline_blank " class=" checkbox-black preview-show-checkbox"
                  :disabled="question.status == 'draft'"></v-checkbox>
              </v-container>
              <v-container class="question-container" fluid>
                <v-row>
                  <v-col class="number-index-center" cols="1">
                    <h3>{{ index + 1 }}</h3>

                  </v-col>
                  <v-col cols="7">
                    <h3>{{ question.question }}</h3>
                  </v-col>
                  <v-col cols="4" class="preview-video-checkbox-container">
                    <v-btn :disabled="!question.video" @click="showVideo(question.video)"
                      :style="{ backgroundColor: topicStore.topic.BtnColor }">Video
                      anzeigen</v-btn>
                  </v-col>
                </v-row>
                <v-row class="answer-row">
                  <v-col class="answer-col" cols="1"></v-col>
                  <v-col class="answer-col" cols="10">
                    <h3>Fakt</h3>
                  </v-col>
                  <v-col class="answer-col preview-icon" cols="1">
                    <v-icon color="blue-grey-darken-2 material-symbols-rounded"
                      :icon="giveIcon(question.answer, 'Fakt')"></v-icon>
                  </v-col>
                </v-row>
                <v-row class="answer-row">
                  <v-col class="answer-col " cols="1"></v-col>
                  <v-col class="answer-col" cols="10">
                    <h3>Mein Vibe</h3>
                  </v-col>
                  <v-col class="answer-col preview-icon" cols="1">
                    <v-icon color="blue-grey-darken-2  material-symbols-rounded"
                      :icon="giveIcon(question.answer, 'Mein Vibe')"></v-icon>
                  </v-col>
                </v-row>
                <v-row class="answer-row">
                  <v-col class="answer-col" cols="1"></v-col>
                  <v-col class="answer-col" cols="10">
                    <h3>Blödsinn</h3>
                  </v-col>
                  <v-col class="answer-col preview-icon" cols="1">
                    <v-icon color="blue-grey-darken-2 material-symbols-rounded"
                      :icon="giveIcon(question.answer, 'Blödsinn')"></v-icon>
                  </v-col>
                </v-row>
              </v-container>
            </v-container>
          </template>
        </v-container>
        <v-dialog v-model="dialog" width="500">
          <v-card>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1 navigation-button-normal" @click="dialog = false">
                <v-icon color="blue-grey-darken-2  material-symbols-rounded" icon="close"></v-icon>
              </v-btn>
            </v-card-actions>
            <v-card-title class="text-h5">Dies ist das Erklärvideo</v-card-title>
            <v-card-text>
              <video width="80%" controls>
                <source :src="videoUrl" type="video/mp4">
                Your browser does not support the video tag.
              </video>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
    </v-container>
  </div>
</template>

<script setup>
import router from "@/router";
import { onMounted, ref } from "vue";
import LoginLogo from "@/assets/LoginLogo.png";
import { getQuestionPerTopicId } from "@/services/APIService";
import { useTopicStore } from "@/stores/TopicStore";
import { useQuestionStore } from "@/stores/QuestionStore";
import NavigationButtonLeftComponent from "@/components/NavigationButtonLeftComponent.vue";
import BackgroundWaves from "@/components/BackgroundWavesComponent.vue";

const topicStore = useTopicStore();
const questionStore = useQuestionStore();
let topic = topicStore.topic;
let questions = ref([]);
let questionsNormal = ref([]);
let questionsDraft = ref([]);
let questionsNundD = ref([]);
let showContents = ref(false);
let buttonText = ref('Inhalt anzeigen');
const dialog = ref(false);
const videoUrl = ref("");
const checkedQuestions = ref([]);
const status = "normal";
const questionCount = ref(0);

const back = async () => {
  await router.back();
};

const filterQuestionsByStatus = (questionsArray, status) => {
  const newQuestionsArray = [];
  for (let i = 0; i < questionsArray.length; i++) {
    if (questionsArray[i].status === status) {
      newQuestionsArray.push(questionsArray[i]);
    }
  }
  return newQuestionsArray;
};

const showContent = () => {
  showContents.value = !showContents.value
  showContents.value ? buttonText.value = "Inhalt verstecken" : buttonText.value = "Inhalt anzeigen"
};

const startQuiz = () => {
  const selectedQuestions = questionsNormal.value.filter((_, index) => checkedQuestions.value.includes(index));

  questionStore.setQuestion(selectedQuestions);

  router.push({ name: "start" });
};

const giveIcon = (answer, value) => {
  return value === answer ? "check" : "close";
};

const showVideo = (videoSource) => {
  videoUrl.value = `https://www.gummilovegame.ch/video/${videoSource}`;
  dialog.value = true;
};

onMounted(async () => {
  const id = topicStore.topic.id;
  questions.value = await getQuestionPerTopicId(id);

  questionsNormal.value = filterQuestionsByStatus(questions.value, status);
  questionCount.value = questionsNormal.value.length;

  questionsDraft.value = filterQuestionsByStatus(questions.value, "draft");

  questionsNundD.value = [...questionsNormal.value, ...questionsDraft.value];

  questionStore.setQuestion(questionsNormal.value)
  checkedQuestions.value = questionStore.questions.map((_, index) => index);
});
</script>