import axios from "axios";
import {useAuthStore} from "@/stores/AuthStore";

axios.defaults.baseURL = "https://www.gummilovegame.ch"

axios.interceptors.request.use(
    function (config){
        const authStore = useAuthStore();
        console.log(authStore.token)
        const token = localStorage.getItem('token')
        if (token) {
            console.log(localStorage.getItem('token'))
            config.headers['Authorization'] = `Bearer ${token}`;


        }
        return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
);