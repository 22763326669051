import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import "./plugins/axios";
import { loadFonts } from "./plugins/webfontloader";
import "./css/style.css";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import useAuth from "./composables/useAuth";
import axios from 'axios'

loadFonts();

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const { attempt } = useAuth();

const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(vuetify);

attempt().then(() => {
  console.log("attempt() done");
  app.mount("#app");
});

axios.defaults.baseURL = 'https://www.gummilovegame.ch'
axios.defaults.withCredentials = true
axios.defaults.withXSRFToken = true
