import { computed, reactive } from "vue";
import axios from "axios";
import { useAuthStore } from "@/stores/AuthStore";

const state = reactive({
  authenticated: false,
  user: null,
});

export default function useAuth() {
  const authenticated = computed(() => state.authenticated);
  const user = computed(() => state.user);

  const setAuthenticated = (authenticated) => {
    state.authenticated = authenticated;
  };

  const setUser = (user) => {
    state.user = user;
  };

  const login = async (credentials) => {
    await axios.get("/sanctum/csrf-cookie");
    const authStore = useAuthStore();

    axios
      .post("/login", credentials)
      .then((response) => {
        authStore.setToken(response.data.token);
        localStorage.setItem("token", response.data.token);
        return attempt();
      })
      .catch((e) => {
        return Promise.reject(e.response.data.errors);
      });
  };

  const attempt = async () => {
    console.log("attempt() called");
    await axios.get("/sanctum/csrf-cookie");

    try {
      let response = await axios.get("/api/user");

      setAuthenticated(true);
      setUser(response.data);

      console.log("attempt successful");

      return response;
    } catch (e) {
      console.log("attempt failed");
      setAuthenticated(false);
      setUser(null);
      console.log(authenticated.value);
    }
  };

  const logout = async () => {
    try {
      await axios.post("/logout");
      setAuthenticated(false);
      setUser(null);
    } catch (e) {
      console.error("Logout failed:", e);
    }
  };

  return {
    authenticated,
    user,
    login,
    logout,
    attempt,
  };
}
